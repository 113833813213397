import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayDate } from 'utils/dates';
import './printComponent.scss';
import { Box, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { formatNumber } from '../../utils/numbers';


const PrintComponentOrder = React.forwardRef(({ orders, state, prod = [], setState, subscription }, ref) => {



    console.log(orders[0]);

    const { prices } = useSelector((state) => state.pricesPrin)





    return (
        <div className="print-container" id="content-to-print" ref={ref}>

            <div>
                <div>
                    {orders?.map((day) => {
                        return day.orders.map((order, i) => {
                            const date = order.deliveryDate
                                ? getDisplayDate(order.deliveryDate)
                                : getDisplayDate(order.scheduleDate);
                            if (order.status !== 'cancelada') {
                                return (
                                    <div key={`${i}_${order.id}`} className="print-entry">
                                        <div className="print-entry-header">
                                            <div className="print-header-info">
                                                <span>
                                                    <Typography className="print-label">Fecha de entrega:  {date}</Typography>
                                                </span>
                                                <span>
                                                    <Typography className="print-label">Comercio:   {order.restaurant.name} </Typography>

                                                </span>
                                                <span>
                                                    <Typography className="print-label">Proveedor:   {order.supplier.name} </Typography>

                                                </span>

                                            </div>

                                        </div>
                                        <div className="print-entry-header">
                                            <div className="print-header-info">



                                                <span>
                                                    <Typography className="print-label">Código: {order.code}</Typography>
                                                </span>
                                                <span>
                                                    <Typography className="print-label">Horario de entrega: {order?.restaurant.hourStart} a   {order?.restaurant.hourEnd}</Typography>
                                                </span>
                                            </div>

                                        </div>
                                        <div className="print-entry-body">
                                            <table className="print-table">
                                                <thead>
                                                    <tr>
                                                        <th className="print-column"> <Typography>Producto</Typography> </th>
                                                        <th className="print-column"> <Typography>Medida</Typography></th>
                                                        <th className="print-column"><Typography>Cantidad</Typography> </th>
                                                        {
                                                            prices.subtotal === 0 || !subscription || subscription.plan.abreviacion !== 'gold' ? null :
                                                                <th className="print-column"><Typography>Precio</Typography> </th>
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.products.map((product) => {

                                                        if (product.requestedQuantity === '0.0') {
                                                            return null
                                                        }

                                                        let pre = 0


                                                        if (product.confirmedQuantity === undefined) {

                                                            pre = product.price * product.requestedQuantity
                                                        } else if (product.confirmedQuantity === null && product.deliveredQuantity === null) {

                                                            pre = product.price * product.requestedQuantity

                                                        } else if (product.confirmedQuantity != null && product.confirmedQuantity === product.requestedQuantity) {

                                                            pre = product.price * product.requestedQuantity

                                                        } else if (product.confirmedQuantity === null && product.deliveredQuantity != product.requestedQuantity) {

                                                            pre = product.price * product.deliveredQuantity

                                                        } else if (product.confirmedQuantity === null && product.deliveredQuantity === product.requestedQuantity) {

                                                            pre = product.price * product.requestedQuantity

                                                        } else {

                                                            pre = product.price * product.confirmedQuantity
                                                        }
                                                        return (
                                                            <tr key={product.id}>

                                                                <td className="print-column"> <Typography>{product.name}</Typography>  </td>
                                                                <td className="print-column">
                                                                    <Typography>{product.measurementUnit}</Typography>
                                                                </td>
                                                                <td className="print-column">

                                                                    {product.deliveredQuantity !== null
                                                                        ? <Typography>{product.deliveredQuantity}</Typography>
                                                                        : product.confirmedQuantity !== null
                                                                            ? <Typography>{product.confirmedQuantity}</Typography>
                                                                            : <Typography>{product.requestedQuantity}</Typography>
                                                                    }
                                                                </td>
                                                                {
                                                                    prices.subtotal === 0 || !subscription || subscription.plan.abreviacion
                                                                        !== 'gold' ? null :
                                                                        <td className="print-column">
                                                                            <Typography textAlign='end' > {pre ? `$${formatNumber(pre)}` : '-'}</Typography>

                                                                        </td>

                                                                }

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />

                                        {
                                            prices.subtotal === 0 || !subscription || subscription.plan.abreviacion
                                                !== 'gold' ? null :
                                                <Box border={1} borderColor={'gray'} padding={1.5} mb={2} >

                                                    <Box mt={1.5} display='flex' flexDirection='row' justifyContent='space-between' >
                                                        <Typography className="print-label">SubTotal:  </Typography>
                                                        <Typography > ${prices.subtotal} </Typography>
                                                    </Box>
                                                    <Box mt={1.5} display='flex' flexDirection='row' justifyContent='space-between' >
                                                        <Typography className="print-label">IVA:  </Typography>
                                                        <Typography > ${prices.iva} </Typography>
                                                    </Box>
                                                    <Box mt={1.5} display='flex' flexDirection='row' justifyContent='space-between' >
                                                        <Typography className="print-label">Total:  </Typography>
                                                        <Typography > ${prices.total} </Typography>
                                                    </Box>




                                                </Box>

                                        }





                                        <span>
                                            <Typography className="print-label">Notas del Comercio: </Typography>
                                            <Typography className="print-label">{order.note} </Typography>

                                        </span>
                                    </div>
                                );
                            }
                        });
                    })}
                </div>
            </div>
        </div>
    );
});

PrintComponentOrder.propTypes = {
    orders: PropTypes.array,
};

export default PrintComponentOrder;
