import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import { isDateAfter, formatDateForOrder, getInputDate } from 'utils/dates';
import { toast } from 'react-hot-toast';

import { createOrder } from '../../../../api/orders';

import { getSupplierWithContact } from 'api/suppliers';
import Loading from 'components/Loading';
import CTA from 'components/CTA';
import ProductReview from '../../../../components/ProductReview';

import { getArrayOfErrors } from 'utils/errors';

import useStore from 'store';

import styles from './summary.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderID, setOrdersAllowed } from '../../../../redux/slices/orderSlice';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';

const Summary = ({ state, changeStep, setState }) => {
  const { restaurant, restaurantList, token, user } = useSelector((state) => state.auth)
  const [supplier, setSupplier] = useState(null);
  const store = useStore((state) => state);
  const [productl, setproductl] = useState([])
  const [date, setDate] = useState(getInputDate());
  const [note, setNote] = useState('');
  // const [restaurantList, setRestaurantList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch()

  console.log(state)

  const confirmOrder = async () => {

    try {


      const scheduleDate = formatDateForOrder(date);
      const productsL = state.products.filter((product) => {
        return product.requestedQuantity !== 0 && product.requestedQuantity !== '0.0' && product.requestedQuantity !== '0'

      })



      const order = {
        supplierId: state.supplierId,
        products: productsL,
        scheduleDate,
        note,
        restaurantId: parseInt(restaurant),
        subtotal: state.subtotal,
        iva: state.iva === '0.00' ? 0 : state.iva,
        total: state.total
      };
      order.products.forEach((product) => {
        product.quantity = product.requestedQuantity;
      });



      const orderResponse = await createOrder(order, token, restaurant, user?.id);

      dispatch(setOrdersAllowed(orderResponse.orders_allowed))
      setState({ ...state, orderId: orderResponse.id });
      changeStep('next');

    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
        setDisabled(false)
      } else {
        toast.error('No se pudo crear la orden. Inténtelo más tarde');
        setDisabled(false)
      }
    }
  };


  const filterProduct = (produ) => {

    const prod = produ.filter((product) => {

      return product.requestedQuantity !== 0

    })

    setproductl(prod)

  }

  useEffect(async () => {

    const supplierData = await getSupplierWithContact(
      parseInt(restaurant),
      state.supplierId,
      token
    );
    setSupplier(supplierData);
  }, [state.supplierId]);

  useEffect(() => {
    filterProduct(state.products)
    if (state.products.length === 0) {
      changeStep('prev');
    }
  }, [state.products]);



  if (!supplier) {
    return <HomeLayouts>
      <Loading />
    </HomeLayouts>;
  }


  return (

    <HomeLayouts>
      <div className={styles.gridColors}>
        <div className={styles.header}>
          <Back className={styles.back} onClick={() => changeStep('prev')} />
          <h1 className={styles.title}>Resumen</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <div
              style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
            >
              <ProductReview
                restaurant={restaurant}
                allowChanges={true}
                state={state}
                prod={productl}
                setState={setState}
                origin="summary"
                shouldRemoveEmpty={true}
              />
              {/*<div className={styles.containerPrices}>
              {state.products.map((val, i) => (
                <div key={i} className={styles.subtitle}>
                  $26.00
                </div>
              ))}
            </div>*/}
            </div>
            <div
              style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
            >
              <button onClick={() => changeStep('prev')} className={styles.addProducts}>
                + Añadir productos
              </button>

            </div>
            <div>
              <p className={styles.subtitle}>Añadir Nota</p>
              <textarea
                className={styles.note}
                rows="4"
                maxLength="255"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <CTA
                text="Confirmar Orden"
                onClick={() => {
                  setDisabled(true);
                  confirmOrder();

                }}
                isDisabled={disabled}
                view="mobile"
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.detailsContainer}>
              <div className={styles.field}>
                <p className={styles.subtitle}>Comercio: </p>
                <p className={styles.value} >
                  {restaurantList.find(
                    (val) =>
                      val.id === parseInt(restaurant),
                  )?.name || '-'}
                </p>
              </div>
              <div className={styles.field}>
                <p className={styles.subtitle}>Dirección de entrega: </p>
                <p className={styles.restaurantName}>
                  {restaurantList.find(
                    (val) =>
                      val.id === parseInt(restaurant),
                  )?.address || '-'}
                </p>

              </div>
            </div>

            <div className={styles.detailsContainer}>
              <div className={styles.field}>
                <p className={styles.subtitle}>Proveedor: </p>

                <p className={styles.value}>{supplier.name}</p>
              </div>
              <div className={styles.field}>
                <p className={styles.subtitle}>Vendedor: </p>
                <p className={styles.value}>{supplier.contact.name}</p>
              </div>
            </div>
            <div className={styles.detailsContainer}>
              <div className={styles.field}>
                <p className={styles.subtitle}>Fecha de entrega: </p>
                <input
                  min={new Date().toJSON().slice(0, 10)}
                  className={styles.dateValue}
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  value={date}
                />
              </div>
            </div>
            <CTA
              text="Confirmar Orden"
              onClick={() => {
                setDisabled(true);
                confirmOrder();
              }}
              isDisabled={disabled}
              view="desktop"
            />
          </div>
        </div>
      </div>
    </HomeLayouts>
  );
};

Summary.propTypes = {
  state: PropTypes.object.isRequired,
  changeStep: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

export default Summary;
