import React, { useState, useEffect } from 'react';

import Suppliers from './components/Suppliers';
import Products from './components/Products';
import Summary from './components/Summary';
import Confirmation from './components/Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const NewOrder = () => {

  const stateL = useLocation();


  const handleInitialStep = () => {
    const storageStep = JSON.parse(localStorage.getItem('step')) || null;
    const storageState = JSON.parse(window.localStorage.getItem('state'));




    if (
      !storageStep ||
      typeof storageStep !== 'number' ||
      isNaN(storageStep) ||
      storageStep > 4 ||
      storageStep < 1
    ) {
      return 1;
    }
    if (storageStep === 1) {
      return 1;
    }
    if (storageState?.supplierId) {
      if (storageStep === 2) return 2;
      if (storageState?.products.length > 0) {
        if (storageStep === 3) return 3;
      }
    }
    if (storageStep === 4 && storageState?.orderId) {
      return 4;
    }
    return 1;
  };


  const [state, setState] = useState({
    supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
    products: JSON.parse(localStorage.getItem('state'))?.products || [],
    note: JSON.parse(localStorage.getItem('state'))?.note || '',
    orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
    subtotal: 0,
    iva: 0,
    total: 0
  });

  console.log(state)

  const [step, setStep] = useState(handleInitialStep());

  const changeStep = (direction) => {
    if (direction === 'next') {
      setStep(step + 1);
    }
    if (direction === 'prev') {
      setStep(step - 1);
    }
  };

  const resetProcess = () => {
    window.localStorage.removeItem('state');
    setState({
      supplierId: null,
      products: [],
      note: '',
      orderId: null,
    });
    setStep(1);
  };




  useEffect(() => {
    window.localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    window.localStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  switch (stateL.state != null ? stateL.state.approved : step) {
    case 1:
      return <Suppliers changeStep={changeStep} setState={setState} state={state} />;
    case 2:
      return <Products changeStep={changeStep} setState={setState} state={state} />;
    case 3:
      return <Summary changeStep={changeStep} setState={setState} state={state} />;
    case 4:
      return (
        <Confirmation
          changeStep={changeStep}
          setState={setState}
          state={stateL.state != null ? stateL.state.approvedOrder : state}
          resetProcess={resetProcess}
        />
      );
    default:
      return <Suppliers changeStep={changeStep} setState={setState} state={state} />;
  }
};

export default NewOrder;
